@import '../../../styles/variables';

.button {
  cursor: pointer;
  padding: 10px 0;
  width: inherit;
  min-height: 52px;
  border: none;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &__name {
    color: $color-white;
    font-family: $font-gt-display;
    font-size: 20px;
    line-height: 1.2;
    font-weight: $font-weight-bold;
    padding-left: 15px;
    padding-right: 10px;
  }

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: hsla(0, 0%, 100%, 0.25);
  }
}

.wrapIcon {
  padding-right: 15px;
}
