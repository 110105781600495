@import '@/styles/variables';

.wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 340px;
  overflow: hidden;

  @media (min-width: $md) {
    height: 500px;
  }

  @media (min-width: $lg) {
    height: 640px;
  }
}

.simpleHero {
  .card {
    bottom: 18%;

    @media (min-width: $lg) {
      bottom: 21%;
    }
  }

  .image {
    min-height: 640px;

    @media (min-width: $md) {
      min-height: 510px;
    }
  }
}

.overlay {
  pointer-events: none;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}

.imageWrapper {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.primaryContent {
  position: absolute;
  bottom: 11%;
  left: 28px;
  color: $color-white;
  width: 76%;

  @media (min-width: $lg) {
    left: 78px;
    bottom: 16%;
    width: calc(100% - 78px);
  }
  @media (min-width: $xxxl) {
    left: 178px;
    width: auto;
  }

  @media (min-width: $fhd) {
    left: 15vw;
  }

  .title {
    font-family: $font-cambon;
    font-size: 40px;
    font-weight: $font-weight-extra-bold;
    line-height: 1.02;
    margin-bottom: 20px;

    @media (min-width: $lg) {
      font-size: 72px;
      margin-bottom: 34px;
    }
  }

  .text {
    font-family: $font-gt-display;
    font-size: 18px;
    line-height: 1.3;

    @media (min-width: $lg) {
      font-size: 20px;
    }
  }
}

.card {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 32%;
  width: auto;
  max-width: calc(100% - 28px);

  @media (min-width: $lg) {
    left: 43px;
    max-width: calc(100% - 86px);
  }
  @media (min-width: $xxxl) {
    left: 178px;
    max-width: calc(100% - 356px);
  }

  @media (min-width: $fhd) {
    left: 15vw;
    max-width: calc(100% - 30vw);
  }
}

.icons {
  position: absolute;
  bottom: 10px;
  z-index: 7002;
  display: grid;
  column-gap: 20px;
  grid-auto-flow: column;
  grid-template-rows: max-content;
  width: 100%;
  justify-content: center;
  padding: 0 18px;

  @media (min-width: $xs) {
    column-gap: 40px;
  }
  @media (min-width: $md) {
    column-gap: 76px;
    bottom: 48px;
    padding: 0;
  }
  @media (min-width: $lg) {
    bottom: 53px;
    padding: 0;
  }
}

.smallGradient {
  height: 22%;
}

.mediumGradient {
  height: 36%;
}

.largeGradient {
  height: 64%;
}

.label {
  display: block;
  text-align: center;
  font-family: $font-gt-display;
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 1.1;
  color: $color-white;
  max-width: 110px;
  @media (min-width: $lg) {
    font-size: 18px;
  }
}

.icon,
.iconLink {
  display: grid;
  align-items: start;
  justify-items: center;
  cursor: pointer;
}
.icon {
  + .icon {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      height: calc(100% - 50px - 8px);
      width: 1px;
      background: rgba($color-white, 0.4);
      top: 50px;
      bottom: 0;
      margin: auto;
      min-height: 32px;

      @media (min-width: $xs) {
        left: -20px;
      }
      @media (min-width: $md) {
        left: -38px;
      }
    }
  }
}

.iconWrap {
  height: 50px;
}

.ctaBtn {
  position: absolute;
  z-index: 22;
  width: 300px;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: $lg) {
    width: 350px;
    bottom: 80px;
  }
}

.markdown {
  font-family: $font-gt-display;
  font-size: 36px;
  font-weight: $font-weight-extra-bold;

  line-height: 1.12;
  margin-bottom: 8px;

  h1 {
    text-transform: uppercase;
    font-size: 32px;

    @media (min-width: $sm) {
      font-size: 36px;
    }

    @media (min-width: $md) {
      font-size: 52px;
    }

    @media (min-width: $lg) {
      font-size: 72px;
    }
  }

  strong {
    color: $color-yellow;
  }
}
