@import '../../../../../styles/variables';

.wrapper {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  z-index: -1;
}

.label {
  padding: 5px 10px 9px;
  min-width: 190px;
  display: inline-flex;
  font-family: $font-cambon;
  font-size: 14px;
  line-height: 1.3;
  font-weight: $font-weight-bold;
}

.card {
  position: relative;
  z-index: 1;
  color: $color-white;
  padding: 15px;
  min-width: 280px;

  @media (min-width: $lg) {
    min-width: 490px;
    padding: 17px 24px 50px;
    width: 100%;
  }
}

.title {
  font-family: $font-cambon;
  font-size: 36px;
  font-weight: $font-weight-extra-bold;

  line-height: 1.12;
  margin-bottom: 8px;
  font-weight: $font-weight-bold;

  @media (min-width: $lg) {
    font-size: 54px;
    line-height: 1.05;
    margin-bottom: 28px;
  }
}

.text {
  font-family: $font-gt-display;
  font-size: 14px;
  line-height: 1.2;
  font-weight: $font-weight-semibold;

  @media (min-width: $lg) {
    font-size: 20px;
  }
}

.shortNews {
  p + p {
    padding: 10px 0;
  }
  a {
    border-bottom: 1px solid #fff;
  }
}
